import React from "react"
import { Modal } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

export default class BootstrapModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }
  }

  openModal = () => {
    const { modal } = this.state
    if (!modal) {
      this.setState({ modal: true })
    }
  }

  closeModal = () => {
    const { modal } = this.state
    if (modal) {
      this.setState({ modal: false })
    }
  }

  render = () => {
    const { picture, altText, thumb, thumbAlt, bio } = this.props

    // console.log(this.state.modal);

    return (
      <>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.modal}
          onHide={this.closeModal}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-4 image">
                  {!!thumb && <GatsbyImage image={thumb} alt={thumbAlt} />}
                </div>
                <div className="col-md-8 bio">{parse(bio)}</div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <button onClick={this.openModal}>
          {!!picture && <GatsbyImage image={picture} alt={altText} />}
        </button>
      </>
    )
  }
}
