import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import Team from '../components/team'
import Clients from '../components/clients'

const WhereWereFrom = props => {

  let {
    location,
    data: {
      wpPage: {
        excerpt,
        content,
        featuredImage: {
          node: {
            altText,
            localFile: {
              childImageSharp: { gatsbyImageData },
            },
          },
        },
        ACF_who_we_are: {
          csr,
          csrCopy,
          csrImage,
          ourLeadership,
          team,
          ourClients,
        },
        seo,
        contentTypeName,
        slug,
      },
    },
  } = props

  const csrThumb = csrImage?.localFile?.childImageSharp?.gatsbyImageData;
  const csrAlt = csrImage?.altText;

  // console.log(ourClients)

  return (
    <Layout location={location}>
      <Seo bodyClasses={`${contentTypeName} ${slug}`} seo={seo} />
      <section className="intro">
        {gatsbyImageData && (
          <GatsbyImage image={gatsbyImageData} alt={altText} loading="eager" />
        )}
        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-6 copy">{parse(content)}</div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-11 col-lg-10 col-xl-9 col-xxl-8">{parse(excerpt)}</div>
          </div>
        </div>
      </section>
      <section id="leadership" className="our-leadership gray">
        <div className="container">
          <div className="row">
            <div className="col-md-11 col-lg-10 col-xl-9 col-xxl-8">{parse(ourLeadership)}</div>
            <Team data={team} />
          </div>
        </div>
      </section>
      <section id="csr" className="pt-0 pb-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 px-0">
              <div className="thumb">
                {!!csrThumb && (
                  <GatsbyImage image={csrThumb} alt={csrAlt} loading="eager" />
                )}
                <div className="copy white">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">{parse(csr)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="white">
        <div className="container">
          <div className="row">
            <div className="col-md-11 col-lg-10 col-xl-9 col-xxl-8">{parse(csrCopy)}</div>
          </div>
        </div>
      </section>
      {!!ourClients?.intro && (
        <section id="clients" className="white">
          <div className="container">
            <div className="row">
              <div className="col-md-11 col-lg-10 col-xl-9 col-xxl-8">
                {parse(ourClients.intro)}
              </div>
              <div className="col-md-12">
                <Clients data={ourClients?.logos} />
              </div>
            </div>
          </div>
        </section>
      )}
    </Layout>
  )
}

export default WhereWereFrom

export const pageQueryWhereWereFrom = graphql`
  {
    wpPage(slug: { eq: "where-were-from" }) {
      title
      excerpt
      content
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      ACF_who_we_are {
        video {
          link
          thumb {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        ourLeadership
        team {
          name
          bio
          thumb {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          picture {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        csr
        csrCopy
        csrImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        ourClients {
          intro
          logos {
            logo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
      seo {
        ...WpYoastSEO
      }
      contentTypeName
      slug
    }
  }
`
