import React from "react"
// import { GatsbyImage } from "gatsby-plugin-image"
// import Swiper core and required modules
import SwiperCore, {
  EffectFade,
  HashNavigation,
  Navigation,
  Pagination,
  Autoplay,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay"
import "swiper/css/navigation"
import { GatsbyImage } from "gatsby-plugin-image"

// install Swiper modules
SwiperCore.use([
  Navigation,
  Pagination,
  HashNavigation,
  Autoplay,
  EffectFade,
])

export default function Clients({ data }) {
  // const { data, cssClass } = props

  // console.log(data)

  return (
    <Swiper
      // navigation={true}
      // autoHeight="false"
      // effect="fade"
      // coverflowEffect={{
      //     rotate: 50,
      //     stretch: 0,
      //     depth: 100,
      //     modifier: 1,
      //     slideShadows: true,
      //   }}
      grabCursor={true}
      // fadeEffect={{ crossFade: true }}
      // initialSlide={3}
      loop={true}
      breakpoints={{
        // when window width is >= 320px
        320: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 5,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        960: {
          slidesPerView: 7,
          spaceBetween: 20,
        },
      }}
      // centeredSlides={true}
      // effect="overflow"
      autoplay={{
        delay: 2000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      }}
      speed={1000}
      lazy={true}
      autoHeight={true}
      // navigation={true}
      // hashNavigation={true}
      // hashNavigation={{
      //   replaceState: true,
      //   watchState: true,
      // }}
      // pagination={{ clickable: true }}
      // navigation={{
      //   nextEl: ".swiper-news-next",
      //   prevEl: ".swiper-news-prev",
      // }}
      // onSwiper={swiper => console.log(swiper)}
      // onSlideChange={() => console.log("slide change")}
    >
      {data &&
        data.map((post, index) => {
          // let content = post.text
          // console.log(post)
          let logo = post?.logo?.localFile?.childImageSharp?.gatsbyImageData
          let altText = post?.logo?.altText || ""

          return (
            <SwiperSlide
              key={index}
              data-hash={`show-${index}`}
              id={`post-${index}`}
            >
              {!!logo && (
                <GatsbyImage loading="eager" image={logo} alt={altText} />
              )}
            </SwiperSlide>
          )
        })}
    </Swiper>
  )
}
