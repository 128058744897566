import React from "react"
// import { GatsbyImage } from "gatsby-plugin-image"
// import Swiper core and required modules
import SwiperCore, {
  EffectFade,
  HashNavigation,
  Navigation,
  Pagination,
  Autoplay,
} from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay"
import "swiper/css/navigation"
import BootstrapModal from "./modal"

// install Swiper modules
SwiperCore.use([
  Navigation,
  Pagination,
  HashNavigation,
  Autoplay,
  EffectFade,
])

export default function Team({ data }) {
  // const { data, cssClass } = props

  // console.log(data)

  return (
    <Swiper
    // navigation={true}
      // autoHeight="false"
      // effect="fade"
      // coverflowEffect={{
      //     rotate: 50,
      //     stretch: 0,
      //     depth: 100,
      //     modifier: 1,
      //     slideShadows: true,
      //   }}
      grabCursor={true}
      // fadeEffect={{ crossFade: true }}
      // initialSlide={3}
      loop={true}
      breakpoints={{
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        960: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      }}
      centeredSlides={false}
      // effect="overflow"
      autoplay={{
        delay: 5000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      }}
      speed={1500}
      lazy={true}
      navigation={true}
      // hashNavigation={true}
      // hashNavigation={{
      //   replaceState: true,
      //   watchState: true,
      // }}
      // pagination={{ clickable: true }}
      // navigation={{
      //   nextEl: ".swiper-news-next",
      //   prevEl: ".swiper-news-prev",
      // }}
      // onSwiper={swiper => console.log(swiper)}
      // onSlideChange={() => console.log("slide change")}
    >
      {data &&
        data.map((post, index) => {
          // let content = post.text
          // console.log(post)
          let picture =
            post?.picture?.localFile?.childImageSharp
              ?.gatsbyImageData
          let altText = post?.picture?.altText || ""

          let thumb =
            post?.thumb?.localFile?.childImageSharp?.gatsbyImageData
          let thumbAlt = post?.thumb?.altText || ""


          return (
            <SwiperSlide
              key={index}
              data-hash={`show-${index}`}
              id={`post-${index}`}
              className="col-md-4"
            >
                <div className="row">
                  <div className="col-md-12">
                    {!!picture && (
                      <BootstrapModal picture={picture} altText={altText} thumb={thumb} thumbAlt={thumbAlt} bio={post.bio} />
                    )}
                  </div>
                  <div className="col-md-12 col-lg-11">
                    <h4 className='text-uppercase mt-3'>{post.name}</h4>
                  </div>
                </div>
            </SwiperSlide>
          )
        })}
    </Swiper>
  )
}
